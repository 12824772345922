import React from 'react'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'


const Donation = () => {
    
  return (
    <div>
        <a style={{margin:'-5px', display:'flex'}} href="/joinus"><h3 style={{backgroundColor:'white', color:'black', width:'40px', textAlign:'center', margin:'0.1rem', borderRadius:'10px', padding:'5px', position:'fixed', zIndex:'1'}} ><BsFillArrowLeftCircleFill size={30} /></h3></a>
      <div>
        {/* <a style={{textDecoration:'none', margin:'1rem', display:'flex'}} href="/joinus"><h4 style={{color: 'black', backgroundColor:'white', width:'50px', textAlign:'center', margin:'0rem', borderRadius:'10px', padding:'5px'}} ><BsFillArrowLeftCircleFill size={40} /></h4></a> */}
        <h1 style={{backgroundColor:"#e9eff7", marginTop:'50px'}} className='chart_heading'>Want to help the victims of gun violence?</h1>
        <h1 style={{color:"#358bcc", textAlign:'center', margin:'3rem'}} className='donation_text'>A small donation from you can make a big difference in their lives.</h1>
        <div className='donation_links'>

          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe style={{}} frameBorder="0" src='https://www.gofundme.com/f/erika-victim-of-gun-violence/widget/large/'  height="450"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
              On May 27, Erika became a victim of Chicago's Gun Violence and was rushed to Mt. Sinai Hospital. She was shot in the back, just missing her spinal cord. The bullet penetrated her back and split into two pieces. Both pieces found her right arm as an exit point.
              The case is under investigation, and no arrests have been made. We do not know who shot her.
              Her recovery is a slow process, and we are asking all of you for your help. All donations are welcomed, no matter the amount.
              We greatly appreciate all your help; thank you. 
            </div>
          </div>

          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe  frameBorder="0" src='https://www.gofundme.com/f/bkfh9-victims-of-gun-violence/widget/large/'  height="450"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
              A little over a month ago my sister and niece were victims of gun violence and in an instant our whole family's lives changed. My sister sustained 
              pretty bad injuries leaving her in the hospital fighting for her life for over a month but is now on her way to recovery. My niece sustained 
              injuries that have left her paralyzed from the chest down and is still in the hospital where she'll be for a while. They are both living miracles 
              and I thank God they are alive. My sister is a single hard-working mother of 4 and loves her children with everything she has. Unfortunately the 
              situation....
            </div>
          </div>

          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe  frameBorder="0" src='https://www.gofundme.com/f/donate-to-help-ms-elsa-avila-hero-teacher/widget/large/'  height="450"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
              Hello, my name is Amanda Verdusco and I created this account for my tia Elsa Avila. She is a SURVIVOR of the terrible tragedy that occurred at Robb 
              Elementary in Uvalde, TX. She is one of the most caring, loving and selfless people you'll ever meet. Always there for anyone whenever they need help. 
              She has such a big heart. She is so giving and never expects anything in return. Her students are her life and even after being injured from a gunshot 
              wound she did everything she could to keep her students safe and calm. She is the best tia anyone could ask for..... 
            </div>
          </div>

          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe  frameBorder="0" src='https://www.gofundme.com/f/help-for-victims-of-domestic-gun-violence/widget/large/'  height="450"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
            Over the weekend (6/5) this family was the victims of domestic gun violence by a family member. By the grace of god they were able to hide in a closet until police got to them. Unfortunately the individual shot up their home, took the lives of the children’s pets before taking his own life in one of the children’s beds.
            The family is still figuring things out but will need help with a place to stay, replacing their belongings and covering treatment for the children's trama and bereavement. Mom and step dad are hanging in there but will need help as they recover and help the children heal. Any little bit helps.
            ....
            </div>
          </div>

          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe frameBorder="0" src='https://www.gofundme.com/f/donate-to-the-texas-school-shooting-victims-fund/widget/large'  height="460"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
            Our hearts go out to the victims and survivors of the tragic mass shooting at Robb Elementary School in Uvalde, Texas.
            Atlantic Packaging, headquartered in Wilmington, North Carolina, is collaborating with Victims First, a network of survivors and relatives affected by previous mass shootings, to raise money for those impacted by the Robb Elementary shooting. 100% of what Victims First collects will go directly to the victims and survivors of the shooting.
            After two weeks, Atlantic will match this campaign's final employee contribution total tracked through GoFundMe ....
            </div>
          </div>

          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe frameBorder="0" src='https://www.gofundme.com/f/sandrick-jorcelin/widget/large/'  height="460"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
              He's 14 years old, he's the eldest of three, he's on the basketball team, and he loves video games. Who would have ever thought that gun violence would suddenly change his life and that of his family? On a quick weekend trip to Laval, QC, Canada, for the occasion of a family member's birthday party, gun violence ended a dream of a day into a horrific nightmare. Sandrick was in the back seat of a car driven by his uncle and accompanied by his mom and two others. While leaving the party, a car pulled up beside their vehicle and opened fire. His uncle did not survive....
            </div>
          </div>


          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe frameBorder="0" src='https://www.gofundme.com/f/33qx3w-end-gun-violence/widget/large/'  height="450"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
            It's not acceptable that we live in a country where we have to worry about our kids being killed at school or our parents being killed while grocery shopping. We cannot sit back and continue to watch shooting after shooting. 
            This August, I'm riding my bike from EverGreene to Vail (Triple ByPass). This is one of the toughest bike rides in the country: 110 miles + 10,000 feet of climbing. I'm doing this bike ride to raise money to benefit Everytown for Gun Safety Support Fund, and any donation will help make an impact. 
            Please consider making a donation, or figure out....
            </div>
          </div>


          <div style={{textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:"3rem"}} className='donation_container'>
            <iframe frameBorder="0" src='https://www.gofundme.com/f/celia-gonzales/widget/large/'  height="450"></iframe>
            <div className='donation_para' style={{width:'298px', marginBottom:'3rem', marginTop:'2rem'}}>
            Hi my name is Natalie Salazar, I am the daughter of Celia or as most people call my mom, Sally. We are deeply saddened with what happened on May 24. It has my family shaken to the core, especially my mom. We give our most sincere condolences to all the families that lost a loved one that day, as my children and I have lost my little cousin Layla on my dads side of the family too and my nephew on my moms side. I speak for my children and I when I say we've been hit from all sides My mom was shot in the face and left to die alone in her home by her very own grandson....
            </div>
          </div>


     
        </div>

        
        <h1 style={{color:"#358bcc", textAlign:'center', margin:'3rem'}} className='donation_text'>
          Thank you for your incredible generosity! We greatly appreciate your donation, and your help. Your support helps to progress our mission. Your support is dear to us, thank you again!
        </h1>
        {/* <h1 style={{color:"#358bcc", textAlign:'center', margin:'3rem', fontSize:'50px'}} className='donation_text'>An eye for an eye only ends up making the whole world blind</h1> */}
      </div>


    </div>
  )
}


export default Donation