import React from 'react'
import DataTable, { createTheme } from 'react-data-table-component';



const Table = () => {

     const data = [
        {
            location: 'Texas',
            deaths: '4,164',
            rank: 1
        },
        {
            location: 'California',
            deaths: '3,449',
            rank: 2
        },
        {
            location: 'Florida',
            deaths: '3,041',
            rank: 3
        },
        {
            location: 'Georgia',
            deaths: '1,897',
            rank: 4
        },
        {
            location: 'Ohio',
            deaths: '1,764',
            rank: 5
        },
        {
            location: 'Pennsylvania',
            deaths: '1,752',
            rank: 7
        },
        {
            location: 'Illinois',
            deaths: '1,745',
            rank: 8
        },
        {
            location: 'North Carolina',
            deaths: '1,699',
            rank: 9
        },
        {
            location: 'Tennessee',
            deaths: '1,473',
            rank: 10
        },
        {
            location: 'Michigan',
            deaths: '1,454',
            rank: 11
        },
        {
            location: 'Missouri',
            deaths: '1,426',
            rank: 12
        },
        {
            location: 'Arizona',
            deaths: '1,265',
            rank: 13
        },
        {
            location: 'Louisiana',
            deaths: '1,183',
            rank: 14
        },
        {
            location: 'Virginia',
            deaths: '1,174',
            rank: 15
        },
        {
            location: 'Indiana',
            deaths: '1,159',
            rank: 16
        },
        {
            location: 'Alabama',
            deaths: '1,141',
            rank: 17
        },
        {
            location: 'South Carolina',
            deaths: '1,131',
            rank: 18
        },
        {
            location: 'New York',
            deaths: '1,052',
            rank: 19
        },
        {
            location: 'Colorado',
            deaths: '922',
            rank: 20
        },
        {
            location: 'Kentucky',
            deaths: '902',
            rank: 21
        },
        {
            location: 'Washington',
            deaths: '864',
            rank: 22 
        },
        {
            location: 'Oklahoma',
            deaths: '826',
            rank: 23 
        },
        {
            location: 'Mississippi',
            deaths: '818',
            rank: 24 
        },
        {
            location: 'Maryland',
            deaths: '803',
            rank: 25 
        },
        {
            location: 'Wisconsin',
            deaths: '717',
            rank: 26 
        },
        {
            location: 'Arkansas',
            deaths: '673',
            rank: 27 
        },
        {
            location: 'Oregon',
            deaths: '592',
            rank: 28 
        },
        {
            location: 'Nevada',
            deaths: '547',
            rank: 29 
        },
        {
            location: 'Minnesota',
            deaths: '513',
            rank: 30
        },
        {
            location: 'Kansas',
            deaths: '494',
            rank: 31
        },
        {
            location: 'New Mexico',
            deaths: '479',
            rank: 32
        },
        {
            location: 'New Jersey',
            deaths: '443',
            rank: 33
        },
        {
            location: 'Utah',
            deaths: '429',
            rank: 34
        },
        {
            location: 'Iowa',
            deaths: '351',
            rank: 35
        },
        {
            location: 'West Virginia',
            deaths: '325',
            rank: 36
        },
        {
            location: 'Idaho',
            deaths: '321',
            rank: 37
        },
        {
            location: 'Massachusetts',
            deaths: '268',
            rank: 38
        },
        {
            location: 'Montana',
            deaths: '238',
            rank: 39
        },
        {
            location: 'Connecticut',
            deaths: '219',
            rank: 40
        },
        {
            location: 'Nebraska',
            deaths: '197',
            rank: 41
        },
        {
            location: 'Alaska',
            deaths: '175',
            rank: 42
        },
        {
            location: 'Wyoming',
            deaths: '154',
            rank: 43
        },
        {
            location: 'Maine',
            deaths: '153',
            rank: 44
        },
        {
            location: 'Delaware',
            deaths: '135',
            rank: 45
        },
        {
            location: 'New Hampshire',
            deaths: '128',
            rank: 46
        },
        {
            location: 'South Dakota',
            deaths: '120',
            rank: 47
        },
        {
            location: 'North Dakota',
            deaths: '100',
            rank: 48
        },
        {
            location: 'Vermont',
            deaths: '76',
            rank: 49
        },
        {
            location: 'Rhode Island',
            deaths: '54',
            rank: 50
        },
        {
            location: 'Hawaii',
            deaths: '50',
            rank: 51
        },
       ];

     const columns = [
        {
          name: <h2>Rank</h2>,
          selector:  row => row.rank,
          sortable: true
        },
        {
          name: <h2>Location</h2>,
          selector:  row => row.location,
          sortable: true
        },
        {
          name: <h2>Deaths</h2>,
          selector:  row => row.deaths,
          sortable: true
        }
      ];



      createTheme('solarized', {
        text: {
          primary: '#ffffff',
          secondary: '#2aa198',
        },
        background: {
          default: '#358bcc',
        },
        context: {
          background: '#cb4b16',
          text: '#FFFFFF',
        },
        divider: {
          default: '#fff',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      }, 'dark');


      const customStyles = {
     
        headCells: {
            style: {
                backgroundColor:'#1c6399'
            },
        },
      
    };





  return (
    <div className='table_map'>
        <DataTable  
            data={data}
            columns={columns}
            theme="solarized"
            fixedHeader
            fixedHeaderScrollHeight="650px"
            customStyles = {customStyles }
        />
    </div>
  )
}
 
export default Table