import React from 'react'
import {Chart} from 'react-google-charts'
import Stats from './Stats.css'



const data = [
    ['Type','Percentage'],
    ['Murder', 43],
    ['Suicide', 54],
    ['Others', 3],
]

export const options3 = {
    title: 'Percentage of U.S. gun deaths, by type 2020',
    // chartArea: {'width': '100%'},
    width:'80vw',
    height:'80vh',
    chartArea:{left:0,top:100,width:"100%"},
    legend: { position: 'bottom' }
}
 

const PieChart = () => {
  return (
    <div id="chart">
            {<Chart 
                chartType='PieChart' 
                data={data}
                options={options3}
                
            /> }            
    </div>
  )
}

export default PieChart