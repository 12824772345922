import React from 'react'
import {Chart} from 'react-google-charts'
import './Stats.css'
import Table from './Table.js'
import PieChart from './PieChart.js'
import stat1 from "../../assets/stat1.png"
import stat2 from "../../assets/stat2.png"
import stat3 from "../../assets/stat3.png"
import stat4 from "../../assets/stat4.png"
import stat5 from "../../assets/stat5.png"
import stat6 from "../../assets/stat6.png"
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'




const data = [
    ['State', 'Per person cost of gun violence', { role: "tooltip", type: "string", p: { html: true } }],
    ['Louisiana', 1793, "Louisiana Ranks 1 in Per person cost of G.V with $1793 and total Cost of $8,397,563,742"],
    ['Mississippi', 1707, "Mississippi Ranks 2 in Per person cost of G.V with $1707 and total Cost of $5,094,840,451"],
    ['Alabama', 1654, "Alabama Ranks 3 in Per person cost of G.V with $1654 and total Cost of $8,064,225,788"],
    ['Missouri', 1606, " Missouri 4 in Per person cost of G.V with $1606 and total Cost of 	$9,818,211,766"],
    ['District of Columbia', 1551, "District of Columbia Ranks 5 in Per person cost of G.V with $1551 and total Cost of $1,076,608,744"],
    ['Alaska', 1483, "Alaska Ranks 6 in Per person cost of G.V with $1483 and total Cost of $1,096,853,827"],
    ['New Mexico', 1472, "New Mexico Ranks 7 in Per person cost of G.V with $1472 and total Cost of $3,074,041,896"],
    ['Tennessee', 1346, "Tennessee Ranks 8 in Per person cost of G.V with $1346 and total Cost of $9,040,391,058"],
    ['Nevada', 1308, "Nevada Ranks 9 in Per person cost of G.V with $1308 and total Cost of $3,920,852,185"],
    ['Montana', 1296, "Montana Ranks 10 in Per person cost of G.V with $1296 and total Cost of $1,361,483,000"],
    ['Arkansas', 1273, "Arkansas Ranks 11 in Per person cost of G.V with $1273 and total Cost of $3,823,410,653"],
    ['Wyoming', 1270, "Wyoming Ranks 12 in Per person cost of G.V with $1270 and total Cost of $735,875,967"],
    ['West Virginia', 1261, "West Virginia Ranks 13 in Per person cost of G.V with $1261 and total Cost of $2,289,447,861"],
    ['South Carolina', 1220, "South Carolina Ranks 14 in Per person cost of G.V with $1220 and total Cost of $6,131,871,379"],
    ['Georgia', 1153, "Georgia Ranks 15 in Per person cost of G.V with $1153 and total Cost of $12,022,059,620"],
    ['Oklahoma', 1133, "Oklahoma Ranks 16 in Per person cost of G.V with $1133 and total Cost of $4,454,746,343"],
    ['Kentucky', 1104, "Kentucky Ranks 17 in Per person cost of G.V with $1104 and total Cost of $4,916,013,511"],
    ['Idaho', 1091, "Idaho Ranks 18 in Per person cost of G.V with $1091 and total Cost of $1,872,822,439"],
    ['Arizona', 1081, "Arizona Ranks 19 in Per person cost of G.V with $1081 and total Cost of $7,585,613,716"],
    ['Colorado', 1006, "Colorado Ranks 20 in Per person cost of G.V with $1006 and total Cost of $5,643,463,539"],
    ['Kansas', 994, "Kansas Ranks 21 in Per person cost of G.V with $994 and total Cost of $2,896,944,856"],
    ['Indiana', 993, "Indiana Ranks 22 in Per person cost of G.V with $993 and total Cost of $6,620,162,619"],
    ['Florida', 961, "Florida Ranks 23 in Per person cost of G.V with $961 and total Cost of $20,169,652,559"],
    ['Maryland', 949, "Maryland Ranks 24 in Per person cost of G.V with $949 and total Cost of $5,742,831,658"],
    ['Pennsylvania', 942, "Pennsylvania Ranks 25 in Per person cost of G.V with $942 and total Cost of $12,061,603,390"],
    ['Ohio', 937, "Ohio Ranks 26 in Per person cost of G.V with $937 and total Cost of $10,923,104,606"],
    ['North Carolina', 937, "North Carolina Ranks 27 in Per person cost of G.V with $937 and total Cost of $9,630,825,236"],
    ['South Dakota', 911, "South Dakota Ranks 28 in Per person cost of G.V with $911 and total Cost of $792,281,879"],
    ['Michigan', 909, "Michigan Ranks 29 in Per person cost of G.V with $909 and total Cost of $9,052,717,404"],
    ['United States average', 860, "UNITED STATES average would Rank 30 in Per person cost of G.V with $860 and total Cost of $280,078,007,871"],
    ['Texas', 839, "Texas Ranks 31 in Per person cost of G.V with $839 and total Cost of $23,744,404,913"],
    ['Virginia', 836, "Virginia Ranks 32 in Per person cost of G.V with $836 and total Cost of $7,081,922,921"],
    ['Illinois', 829, "Illinois Ranks 33 in Per person cost of G.V with $829 and total Cost of $10,614,319,653"],
    ['Vermont', 828, "Vermont Ranks 34 in Per person cost of G.V with $828 and total Cost of $516,409,174"],
    ['North Dakota', 800, "North Dakota Ranks 35 in Per person cost of G.V with $800 and total Cost of $604,618,376"],
    ['Oregon', 795, "Oregon Ranks 36 in Per person cost of G.V with $795 and total Cost of $3,293,209,487"],
    ['Utah', 790, "Utah Ranks 37 in Per person cost of G.V with $790 and total Cost of $2,449,205,431"],
    ['Delaware', 742, "Delaware Ranks 38 in Per person cost of G.V with $742 and total Cost of $713,711,475"],
    ['Maine', 732, "Maine Ranks 39 in Per person cost of G.V with $732 and total Cost of $978,549,460"],
    ['Washington', 716, "Washington Ranks 40 in Per person cost of G.V with $716 and total Cost of $5,301,742,352"],
    ['New Hampshire', 695, "New Hampshire Ranks 41 in Per person cost of G.V with $695 and total Cost of $933,868,891"],
    ['Wisconsin', 670, "Wisconsin Ranks 42 in Per person cost of G.V with $670 and total Cost of $3,882,404,492"],
    ['Nebraska', 619, "Nebraska Ranks 43 in Per person cost of G.V with $619 and total Cost of $1,188,094,374"],
    ['Iowa', 590, "Iowa Ranks 44 in Per person cost of G.V with $590 and total Cost of $1,854,841,339"],
    ['California', 571, "California Ranks 45 in Per person cost of G.V with $571 and total Cost of $22,582,221,851"],
    ['Minnesota', 515, "Minnesota Ranks 46 in Per person cost of G.V with $515 and total Cost of $2,873,534,733"],
    ['Connecticut', 385, "Connecticut Ranks 47 in Per person cost of G.V with $385 and total Cost of $1,381,600,384"],
    ['New Jersey', 373, "New Jersey Ranks 48 in Per person cost of G.V with $373 and total Cost of $3,363,315,893"],
    ['New York', 298, "New York Ranks 49 in Per person cost of G.V with $298 and total Cost of $5,905,381,570"],
    ['Hawaii', 291, "Hawaii Ranks 50 in Per person cost of G.V with $291 and total Cost of $415,152,086"],
    ['Rhode Island', 282, "Rhode Island Ranks 51 in Per person cost of G.V with $282 and total Cost of $299,319,039"],
    ['Massachusetts', 261, "Massachusetts Ranks 52 in Per person cost of G.V with $261 and total Cost of $1,789,658,283"],
]



export const data2 = [
    ["State", "Deaths"],
    ["Alabama", 1141],
    ["Alaska", 175],
    ["Arizona", 1265],
    ["Arkansas", 673],
    ["California", 3449],
    ["Colorado", 922],
    ["Connecticut", 219],
    ["Delaware", 135],
    ["Florida", 3041],
    ["Georgia", 1897],
    ["Hawaii", 50],
    ["Idaho", 321],
    ["Illinois", 1745],
    ["Indiana", 1159],
    ["Iowa", 351],
    ["Kansas", 494],
    ["Kentucky", 902],
    ["Louisiana", 1183],
    ["Maine", 153],
    ["Maryland", 803],
    ["Massachusetts", 268],
    ["Michigan", 1454],
    ["Minnesota", 513],
    ["Mississippi", 818],
    ["Missouri", 1426],
    ["Montana", 238],
    ["Nebraska", 197],
    ["Nevada", 547],
    ["New Hampshire", 128],
    ["New Jersey", 443],
    ["New Mexico", 479],
    ["New York", 1052],
    ["North Carolina", 1699],
    ["North Dakota", 100],
    ["Ohio", 1764],
    ["Oklahoma", 826],
    ["Oregon", 592],
    ["Pennsylvania", 1752],
    ["Rhode Island", 54],
    ["South Carolina", 1131],
    ["South Dakota", 120],
    ["Tennessee", 1473],
    ["Texas", 4164],
    ["Utah", 429],
    ["Vermont", 76],
    ["Virginia", 1174],
    ["Washington", 864],
    ["West Virginia", 325],
    ["Wisconsin", 717],
    ["Wyoming", 154],
  ];

export const data4 = [
    ["State", "Deaths", 'Injuries'],
    // ["Las Vegas, Nevada (2017)", 59, 867],
    ["Orlando, Florida (2016)", 49, 58],
    ["Virginia Tech, Virginia (2007)", 32, 23],
    ["Sandy Hook, Connecticut (2012)", 27, 2 ],
    ["Sutherland Springs, Texas (2017)", 26, 22 ],
    ["Killeen, Texas (1991)", 23, 27 ],
    ["El Paso, Texas (2019)", 23, 23 ],
    ["Uvalde, Texas (2022)", 21, 18 ],
    ["Parkland, Florida (2018)", 17, 17 ],
    ["Killeen, Texas (2009)", 14, 32 ],
    ["San Bernardino, California (2015)", 14, 24 ],
    ["Columbine, Colorado (1999)", 13, 24],
    ["Binghamton, New York (2009)", 13, 4 ],
    ["Aurora, Colorado (2012)", 12, 70],
    ["Thousand Oaks, California (2018)", 12, 16 ],
    ["Washington, D.C (2013)", 12, 8 ],
    ["Virginia Beach, Virginia (2019)", 12, 4 ],
    ["Pittsburgh, Pennsylvania (2018)", 11, 6 ],
    ["Santa Fe, Texas (2018)", 10, 13 ],
    ["Geneva County, Alabama, Virginia (2018)", 10, 6 ],
    ["Buffalo, New York (2022)", 10, 3 ],
    ["Boulder, Colorado (2021)", 10, 1 ],
  ];





export const options = {
    bar: { groupWidth: "80%" },   
    hAxis: {
        titleTextStyle: {
            fontSize: 30, 
            bold: true,   
            color: '#358bcc' 
        },
        title : "Per person cost of G.V.",
        fontSize: 105,
        textStyle : {
            fontSize: 15,
            bold: true,
        }
    },
    vAxis: {
        titleTextStyle: {
            fontSize: 30, 
            bold: true,    
            color: '#358bcc' 
        },
        title : "States",
        fontSize: 105,
        textStyle : {
            fontSize: 15,
            bold: true,
        }
    },
    legend: {position: 'right',  textStyle: {fontSize: 14}}
}



export const options2 = {
    region: 'US',
    displayMode: 'regions',
    resolution: 'provinces',
    colors: ['#FF0000'],
}


export const options4 = {
    // chartArea:{left:0,top:10,width:"100%"},
    bar: { groupWidth: "80%" }, 
    legend: { position: 'bottom' }, 
    vAxis : { 
        textStyle : {
            fontSize: 12 // or the number you want
        }
    },
    hAxis : { 
        textStyle : {
            fontSize: 12 // or the number you want
        }
    },
    tooltip: {isHtml: true}

}


const Stats = () => {
    return (
        <div>
            <div>
                <a style={{margin:'-5px', display:'flex'}} href="/joinus"><h3 style={{backgroundColor:'white', color:'black', width:'50px', textAlign:'center', margin:'0.1rem', borderRadius:'10px', padding:'5px', position:'fixed', zIndex:'1'}} ><BsFillArrowLeftCircleFill size={40} /></h3></a>
            <div className='piechart_container'>
                <div className='pie_note' style={{textAlign:"center", margin:'10px'}}>
                    <p>Note: "All charts and tables can be clicked for more information.</p>
                </div>
            </div>
                <h2 style={{backgroundColor:"#e9eff7", marginTop:'10px'}} className='chart_heading'>The Economic Cost of Gun Violence by State</h2>
                <div>
                    {<Chart 
                    
                        chartType='BarChart' 
                        data={data}
                        options={options}
                        width="100%"
                        height='1500px'
                    /> }
                </div>

                <div> 
                    <h1 style={{backgroundColor:"#e9eff7"}} className='chart_heading'>2020 Firearm Mortality by State</h1>
                    <div className='charthead'>
                        <div>
                                {<Chart 
                                    chartType='GeoChart' 
                                    data={data2}
                                    options={options2}
                                    // width="100%"
                                    // height='1000px'
                                    
                                    /> }
                                <h5 style={{marginLeft: '1rem'}}>Source:<a href='https://wonder.cdc.gov'>https://wonder.cdc.gov</a></h5>
                        </div>
                    </div>
                        <div  className='table_map_container'>
                            <div style={{marginBottom:'3rem'}} className='table_map'>
                                <Table />
                            </div>
                        </div>
                    
                    <div>

                        <h1 className='chart_heading' style={{backgroundColor:"#e9eff7", marginTop:'5rem'}}>Suicides accounted for more than half of U.S. gun deaths in 2020</h1>
                        <div className='piechart_container'>
                            <PieChart />
                            <div className='pie_note'>
                                <p>Note: "Others" includes gun deaths that were unintentional, involved law enforcement or had undetermined circumstances.</p>
                            </div>
                        </div>
                        
                    </div>

                    <h1 className='quote1'>“One Child is worth more than all the <span className='quote_bad' style={{color:'red'}}>GUNS</span> on Earth.”</h1>
                    
                    <div>
                        <h1 style={{backgroundColor:"#e9eff7", marginTop:'8rem'}} className='chart_heading'>Worst mass shootings in U.S since 1991</h1>
                        


                        <div className='vegas_shooting'>
                            <div style={{margin:'1rem', display:'flex', justifyContent:'center'}}>
                                <img style={{width:'60%', margin:'1rem'}} src="https://www.trbimg.com/img-59d7a8cb/turbine/la-vegas-victims-grid/1200" alt="img"/>
                            </div>
                            <div style={{display:"inlineBlock", margin:'2rem', width:'90%'}}>
                                <h1>
                                    <div style={{width:'100%'}}>
                                        The deadliest mass shooting incident committed by an individual in United States history:
                                    </div>
                                    <span style={{fontSize:'15px', width:'100%'}}> <br />
                                        On October 1, 2017, Stephen Paddock, a 64-year-old man from Mesquite, Nevada, opened fire on the crowd attending the Route 91 Harvest music festival 
                                        on the Las Vegas Strip in Nevada. From his 32nd-floor suites in the Mandalay Bay hotel, he fired more than 1,000 bullets, killing 60 people and 
                                        wounding 411 with the ensuing panic bringing the number of injured to 867. About an hour later, he was found dead in his room from a self-inflicted 
                                        gunshot wound. The motive for the mass shooting is officially undetermined.
                                    </span> <br /> <br /><br />

                                    Below are the worst shootings following the most horrifying massacre to date, the 2017 Las Vegas shooting.
                                </h1>
                            </div>
                        </div>



                        <div className='bar_cart_2' style={{margin:'2rem'}}>
                                {<Chart 
                                    chartType='BarChart' 
                                    data={data4}
                                    options={options4}
                                    width="100%"
                                    height='1400px'
                                /> }
                        </div>
                    </div>

                </div>

                <div>
                <h1 style={{backgroundColor:"#e9eff7", marginTop:'5rem'}} className='chart_heading'>Facts you should know</h1>
                    <div className="container_stat">
                        <div className="photos_stat">
                            <img src= {stat1}  alt='image' />
                            <img src= {stat2}  alt='image' />
                            <img src= {stat3}  alt='image' />
                            <img src= {stat4}  alt='image' />
                            <img src= {stat5}  alt='image' />
                            <img src= {stat6}  alt='image' />
                        </div>
                    </div>
                </div>

                    
                </div>
        </div>
  )
}





export default Stats








