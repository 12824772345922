import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Container/Home/Home';
import JoinUs from './Container/JoinUs/JoinUs';
import Stats from './Container/Stats/Stats';
import Donation from './Container/Donation/Donation';
import Lobbying from './Container/Lobbying/Lobbying';
import Documentaries from './Container/Documentaries/Documentaries';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route index element={<Home />} />
            <Route path="joinus" element={<JoinUs />} />
            <Route path="stats" element={<Stats />} />
            <Route path="donation" element={<Donation />} />
            <Route path="lobbying" element={<Lobbying />} />
            <Route path="documentary" element={<Documentaries />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
