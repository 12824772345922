import React from 'react'
import './Lobbying.css'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'



const Lobbying = () => {
  return (
    <div>
      <a style={{margin:'-5px', display:'flex'}} href="/joinus"><h3 style={{backgroundColor:'white', color:'black', width:'50px', textAlign:'center', margin:'0.1rem', borderRadius:'10px', padding:'5px', position:'fixed', zIndex:'1'}} ><BsFillArrowLeftCircleFill size={40} /></h3></a>
      <div className='pie_note2' >
          <p>Note: You will be redirected to sandy hook promise foundation's website</p>
      </div>
      <h1 style={{backgroundColor:"#e9eff7"}} className='chart_heading'>This petition was started by sandy hook promise foundation</h1>

      <div className='lobby_main' style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
          <iframe src='https://action.sandyhookpromise.org/p/dia/action4/common/public/index.sjs?action_KEY=23172' style={{width: '100%', height: '102vh'}}  frameBorder="0" ></iframe>
      </div>
    </div>
  )
}

export default Lobbying
// filter:'brightness(0.4)'