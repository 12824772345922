import React from 'react'
import gun_png from "../../assets/gun_png.png"
import handgun from "../../assets/handgun.png"
import donate from "../../assets/donate.jpg"
import './Home.css'
import { Link } from "react-router-dom";
import { BsInstagram } from 'react-icons/bs'
import { AiFillInstagram } from 'react-icons/ai'
import { MdEmail } from 'react-icons/md'





const Home = () => {
  return (
    <div>
      <div className='head_img_cont'>
        <div>
          <img  className='head_img' src= {gun_png} alt='image' />
        </div>
      </div>
       

       <div className='top_header_container'>
          <div className='head_text'>
            <h1>A KID'S LIFE SHOULD NEVER BE ENDED BY A GUN</h1>
            <p className='head_para'>Stopping gun violence for future generations.</p>
          </div>

          <a className='button1' href="/joinus"> GET INVOLVED</a> 
          {/* <Link className='button1' to="joinus"></Link> */}

      </div>

      <div className='second_part'>

        <div className='second_part_text' style={{width: "40%", float:"left", height:'100%'}}>
          <h1>COMMITTED TO FIXING THE LOOSE GUN LAWS</h1>
          <p >
            Are you willing to give up some freedoms for a child's life? Are you willing to put the guns down for our country's future? There are many problems but we are tackling the toughest ones first.
          </p>
        </div>

        <div className='pistol_img' style={{width: "50%", height:'100%', float:"right"}}>
          <img style={{width: "100%"}} src= {handgun} alt='image' />
        </div>
      </div>


<div>
      <div className='third_col' style={{ backgroundImage: `url(${donate})`, backgroundSize: 'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center' }}>

      <h1>MAKE A <br/> DIFFERENCE</h1>
      <a className='button' href="/donation">Donate</a> 

        <div>
            {/* <p className='footer2' style={{width:'40%', bottom: '0'}}>
              Society
              <br />
              Rua de Rapina 29
              <br />
              Carpio de Alcaba, Salamanca 37496
            </p>
          <div style={{float:'right',width:'60%'}}>
            <p className='footer2' style={{width:'40%', bottom: '0'}}>
              123-456-7890
              <br />
              NoGunsForTexas@gmail.com
              <br />
              @reallygreatsite
            </p>
          </div> */}
          {/* <div style={{position:'absolute', bottom:'0', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}> */}
        </div>



          <div style={{position:'absolute', display:'flex', justifyContent:'center', bottom:'0', textAlign:'center',  width: '100%'}}>
            <a target="_blank"  href='https://www.instagram.com/nogunsfortexas/'><AiFillInstagram size={50} style={{color:'white', border:'#fff', borderStyle:'solid', borderRadius:'10px', margin:'1rem'}} /></a>
            <a target="_blank"  href="mailto:nogunsfortexas@gmail.com" ><MdEmail size={50} style={{color:'white', border:'#fff', borderStyle:'solid', borderRadius:'10px', margin:'1rem'}} /></a>
          </div>
      </div>
      </div>

    </div>
  )
}



export default Home


