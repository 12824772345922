import React from 'react'
import './JoinUs.css'
import lobbying from "../../assets/lobbying.jpg"
import documantery from "../../assets/documentary.jpg"
import stats from "../../assets/stats.jfif"
import campaigns from "../../assets/campaigns.jpg"
import { Link } from "react-router-dom"
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'


const JoinUs = () => {
  return (
    <div className='join_main'>
        <div>
        <a style={{margin:'-5px', display:'flex'}} href="/"><h3 style={{backgroundColor:'white', color:'black', width:'40px', textAlign:'center', margin:'0.1rem', borderRadius:'10px', padding:'5px', zIndex:'1'}} ><BsFillArrowLeftCircleFill size={30} /></h3></a>

        {/* <a style={{display:'flex'}} href="/"><h4 style={{backgroundColor:'black', color: 'white', width:'0px', textAlign:'center', margin:'0rem', borderRadius:'10px', padding:'5px'}} ><BsFillArrowLeftCircleFill size={40} /></h4></a> */}
        </div>
        <h1 className='join_head'>FIGHTING FOR OUR LIVES</h1>
        <h1 className='join_head2'>PLEASE CHECK THESE OUT</h1>

        <div className='img_container'>

            <Link className='joinus_links' to="/stats">
                <div className='img1'>
                    <img className='join_img' style={{borderRadius: '10%'}} src={stats}  alt='image' />
                    <h3 className='joinus_title' >STATS AND NUMBERS</h3>
                    <p className='img_para'>We organize and keep the data of number of the victims, the date and the location of the shootings.</p>
                </div>
            </Link>

            <Link className='joinus_links' to="/lobbying">
                <div className='img1'>
                    <img className='join_img' style={{borderRadius: '10%'}} src={lobbying}  alt='image' />
                    <h3 className='joinus_title' >PETITION</h3>
                    <p className='img_para'>Pushing state governments nationwide to protect our lives and our kid's lives.</p>
                </div>
            </Link>

            <Link className='joinus_links' to="/donation">
                <div className='img1'>
                    <img className='join_img' style={{borderRadius: '10%'}} src={campaigns}  alt='image' />
                    <h3 className='joinus_title' >DONATIONS</h3>
                    <p className='img_para'>We create and share petitions and donations to help those affected by mass shootings.</p>
                </div>
            </Link>

            <Link className='joinus_links' to="/documentary">
                <div className='img1'>
                    <img className='join_img' style={{borderRadius: '10%'}} src={documantery}  alt='image'  />
                    <h3 className='joinus_title' >DOCUMENTARIES</h3>
                    <p className='img_para'>Don't like reading? Watch some of the best documentaries about gun violence to know what's going on around you.</p>
                </div>
            </Link>


        </div>


    </div>
  )
}

export default JoinUs













































        {/* <div className='image_container'>
            <figure>
                <img src="http://placekitten.com/100" width="150px" height="150px" />
                <figcaption style={{color:'white'}}>First image</figcaption>
            </figure>
            <figure>
                <img src="http://placekitten.com/100" width="150px" height="150px" />
                <figcaption style={{color:'white'}}>First imagerst imrst  <br /> imrst imrst imrst  im</figcaption>
            </figure>
            <figure>
                <img src="http://placekitten.com/100" width="150px" height="150px" />
                <figcaption style={{color:'white'}}>First image</figcaption>
            </figure>
        </div> */}