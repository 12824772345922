import React from 'react'
import './Documentaries.css'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'



const Documentaries = () => {


  return (
    <div>
        <a style={{margin:'-5px', display:'flex'}} href="/joinus"><h3 style={{backgroundColor:'white', color:'black', width:'50px', textAlign:'center', margin:'0.1rem', borderRadius:'10px', padding:'5px', position:'fixed', zIndex:'1'}} ><BsFillArrowLeftCircleFill size={40} /></h3></a>
        <h1 style={{backgroundColor:"#e9eff7"}} className='chart_heading'>Must watch documentaries/films on gun violence</h1>

        <div className='vid_main'>

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/X9wKQrXBY7g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >The tragic shooting of 20 children and six teachers at an 
                elementary school in Newtown, Connecticut stunned people on 
                both sides of the border. Somehow it seemed worse than other 
                recent mass shootings - perhaps because of the age of the 
                children, or the popular military-style assault rifle used, 
                the AR-15.
              </h4>
            </div>
           
          </div>
         

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/C4eHkuqtJi0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >33,000 Americans are killed by guns each year. America's 
                325 million residents own an estimated 347 million firearms. 
                Not surprisingly, gun violence has become one of the most 
                urgent public health issues facing Americans. In 91%, gun 
                violence victims tell their heart-breaking stories of loss, 
                pain, and a heroic search for hope in a nation stalled in a 
                senseless gun control debate. 
              </h4>
            </div>
           
          </div>
         

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/yL5j5RDK34A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >100+ people are killed from guns every day in USA 
                  Gun deaths worldwide total about 250,000 yearly
                  Why are Americans obsessed with guns?
                  Why is SecondAmendment so controversial?
                  How do other countries deal with GunControl?
                  Watch Gun Violence: The Hidden War on WION Wideangle. 
              </h4>
            </div>
           
          </div>
         

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/kKMeCJtlVuU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >In 91%, a cross-section of U.S. gun violence victims 
                tell their heartbreaking stories of loss, pain, and a heroic 
                search for hope in a nation stalled in a senseless gun 
                control debate. Throughout these otherwise unrelated 
                shootings, we find a common thread – the gunmen had 
                all-too- easy access to the virtually untraceable, 
                high-powered weaponry used in their attacks.
              </h4>
            </div>
          </div>
         

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/cVfzxLvIxi8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >A Killer on Floor 32 takes you inside the deadliest mass 
                shooting in modern American history which took place in 
                Route 91 Harvest music festival on the Las Vegas Strip in 
                Nevada, It uses never before seen footage, and exclusive 
                access to tell the story of that tragic night in Las Vegas.
              </h4>
            </div>
          </div>
         

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/rZmzmekZ2Zg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >Mass shootings have been described as an epidemic that only happens in the United States.
                  The latest killed 19 children and two teachers at an elementary school in Texas.
                  Grief is turning to anger, as Americans once again demand tougher gun control laws.
                  So what's stopping action in the U.S. Congress to prevent another tragedy?
              </h4>
            </div>
          </div>
         

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/yxGrsxpcqeA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >Mass shootings have been described as an epidemic that only happens in the United States.
                  The latest killed 19 children and two teachers at an elementary school in Texas.
                  Grief is turning to anger, as Americans once again demand tougher gun control laws.
                  So what's stopping action in the U.S. Congress to prevent another tragedy?
              </h4>
            </div>
          </div>
         

          <div className='vid_container'>
            <iframe className='vid' width="800" height="250" src="https://www.youtube.com/embed/sZ2KQhJX1cQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <div className='vid_desc'>
              <h4 >We investigate the true price of a gunshot, sitting down with families, including 
                a nine-year-old girl in a wheelchair. 7 Action News talks with shooting victims 
                struggling to pay medical bills and examines the cost to taxpayers. We also have 
                an exclusive look inside the gun vault for Detroit Police.
              </h4>
            </div>
          </div>

      </div>

    </div>
  )
}
export default Documentaries
